/*************************
        User Module
**************************/

dl.permission, dl.role, dl.user {
    dd {
        margin-bottom: 15px;
    }
}

.btn {
    text-shadow: 1px 1px 8px rgba(19, 17, 17, 0.25);
}

.profile-preview img{
    text-align: center;
    -webkit-border-radius: 50% 50%;
    -moz-border-radius: 50% / 50%;
    border-radius: 50% / 50%;
    border: 4px solid #CCCCCC;
    margin-bottom: 10px;
}

.dropzone {
    border: 2px dashed #0087F7 !important;
    border-radius: 5px !important;
    background: white !important;
    font-weight: bold !important;
    font-size: 18px !important;
    color: #646C7F !important;
}

.dept-name{
    padding: 5px;
}

.row{
    margin-bottom: 10px;
}

.checkboxall {
    min-height: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.control {
    font-size: 14px;
    position: relative;
    display: block;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #e6e6e6;
}
/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
    background: #ccc;
}

/* Checked state */
.control input:checked ~ .control__indicator {
    background: #2aa1c0;
}

/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
    background: #0e647d;
}

/* Check mark */
.control__indicator:after {
    position: absolute;
    display: none;
    content: '';
}

/* Show check mark */
.control input:checked ~ .control__indicator:after {
    display: block;
}

/* Checkbox tick */
.control--checkbox .control__indicator:after {
    top: 4px;
    left: 8px;
    width: 3px;
    height: 8px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}


.disabled {
       pointer-events: none;
       cursor: default;
    } 